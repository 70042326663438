.anunt-container {
    display: grid;
    grid-auto-columns: 1fr; 
    grid-template-columns: 0.3fr 2.3fr 1.1fr 0.3fr; 
    /* grid-template-rows: 0.25fr 2.1fr 0.1fr auto;  */
    grid-template-areas: 
    "searcha searcha searcha searcha"
    ". anuntDet utilizator ."
    "asemanatoare asemanatoare asemanatoare asemanatoare"
    "footer footer footer footer";
    margin: 0;
    gap: 20px 20px;
    background-color: #dccf0e4f;
     

}
.anunt-container .user-details{
    display: flex;
    flex-direction: column;
    grid-area: utilizator;
    background-color: white;
    height: 100%;
    margin: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border: 3px solid #000000;
    border-radius: 10px ;
    height: 500px;
    box-shadow: 3px 4px 0px 0px #000000;
    padding: 3% 5% 5% 5%;
    align-items: center;

}
.anunt-container .user-details:hover{
    cursor: pointer;
}
.anunt-container .searchaa {
    grid-area: searcha; 
    background-color: #AA6339;
    
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
    /* border: 1px solid #ffffff; */
    padding: 2% 5% 2% 5%;
    color: var(--alb);
    border-bottom: 5px solid black;
    margin-bottom: 3%;
}

.anunt-container .profile_img{
    width: 200px;
    height: 200px;
    border-radius: 50%;

}
.anunt-details img{
    width: 100%;
    border: 3px solid #000000;
    border-radius: 10px;
    box-shadow: 3px 4px 0px 0px #000000 ;

}

.anunt-text {
    margin-top: 1%;
    background-color: #ffffff;
    border: 3px solid #000000;
    border-radius: 10px ;
    width:100%;
    box-shadow: 3px 4px 0px 0px #000000;
    padding: 3% 5% 5% 5%;
}

.asemanatoare {
    grid-area: asemanatoare;
    background-color: #ffffff;
    height: 100%;
    margin: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border: 3px solid #000000;
    border-radius: 10px ;
    box-shadow: 3px 4px 0px 0px #000000;
    padding: 3% 5% 5% 5%;
    align-items: center;

}

/* .urgent .slick-track {
    left: 0px !important;
}

.urgent .slick-list {
    padding: 0px 0px !important;
} */


@media only screen and (max-width: 767px) {
    .anunt-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
        "searcha"
        "anuntDet"
        "utilizator";     
        display: flex;
        flex-direction: column;
        align-items: center;
        

    }
    .anunt-container .user-details{
        width: 100%;
        margin: 0;
        padding: 0;
        height: auto;

        h4{
            margin-bottom: 0px;
        }

    }
    .anunt-container .searchaa {
        width: 100%;
        margin: 0;
        padding: 4%;
        
    }
    .anunt-container .profile_img{
        width: 200px;
        height: 200px;
        border-radius: 50%;

    }
    .anunt-container .anuntDet{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .anunt-container .asemanatoare{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .anunt-container .anuntDet{
        grid-area: anuntDet;
        
        height: 100%;
        margin: 10px;
        
        align-items: center;
        justify-content: center;
    
    }
    .anunt-container .asemanatoare{
        grid-area: asemanatoare;
        background-color: #ffffff;
        height: 100%;
        margin: 10px;
        padding: 10px;
        align-items: center;
        justify-content: center;
    
    }
    .anunt-details img{
        width: 100%;
        height: auto;
    }
    .anunt-container p {
        
    }
}