/* search.css */
.search-results  {
  margin-top: 20px;
  width: 100%;
  display: flex;
  z-index: 1;
}

.cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff; /* Moved color property to the .cont class */
}

#img-anunt {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.search-results ul{
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  width: 100%;
  min-width: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Added flex-wrap property to allow wrapping */
  gap: 15px 10px;
}

.search-results li,
.urgent li
 {
   border: none;
  
  
  margin-bottom: 10px;
  display: flex;
}

.search-results h3, .urgent h3{
  font-size: 120%;
  margin-bottom: 5px;
  margin-top: 0px;
}

.search-results p, .urgent p {
  margin: 5px;
  width: 100%;
}

.search-results .anunt-pr{
  
  background-color: var(--alb);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--negru);
  box-shadow: 3px 4px 0px 0px #ad6b6b, 4px 6px 0px 0px #000000;
  color: var(--negru);
  border-radius: 20px;
  width: 100%; /* Adjusted width to be 100% */
  max-width: calc(20.00% - 10px); /* Added max-width to limit to one-third of the row */
  box-sizing: border-box; /* Include border and padding in width calculation */
  height: 350px;
}
.urgent .anunt-pr{
  height: max-content;
  background-color: var(--alb);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--negru);
  box-shadow: 3px 4px 0px 0px #6b74ad, 4px 6px 0px 0px #000000;
  color: var(--negru);
  border-radius: 20px;
  width: 100% ; /* Adjusted width to be 100% */
  margin: 0px 5px;
  box-sizing: content-box; /* Include border and padding in width calculation */
  height: 350px;
  
  
}
.search-results .anunt-pr:hover, .urgent .anunt-pr:hover{
  background-color: var(--alb);
  border: 3px solid black;
  box-shadow: 0px 0px 0px 0px #000000;
  cursor: pointer;
  transform: translate( 4px, 6px);
}

.search-results .anunt-pr li, .urgent .anunt-pr li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5%;
  padding-bottom: 1%;
  bottom: 50px;

}

.search-results .anunt-pr li p, .urgent .anunt-pr li p{
  font-size: 14px;
  text-align: justify;
  
}

.search-results .anunt-pr li:last-child, .urgent .anunt-pr li:last-child{
  margin-bottom: 10%;
}

#img-anunt {
  width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid black;
  margin: 5%;
}

#titlu {
  margin: 10px;
  width: 100%;
  white-space: normal; 
  overflow: visible;

}

.slide-margin {
  margin: 0px 10px; /* Adjust the margin value as needed */
}

.search-results {
  margin-top: 20px;
  width: 100%;
  display: flex;
  
}

.slick-container {
  /* height: 400px !important; */
}



.slick-slider, .slick-list, .slick-track {
  /* display: flex !important; */
  height: 100% !important;
 align-items: stretch !important;
}

@media screen and (max-width:1200px) {
  .search-results .anunt-pr{
    width: 100%;
    max-width: none;
    flex-basis: 24%;
    margin: 0px;
    height: 300px;
    z-index: 1;
  }
  .urgent .anunt-pr{
    
    height: 300px;
    
  }
  
  
}
@media screen and (max-width: 940px){
  .search-results .anunt-pr{
    width: 100%;
    max-width: none;
    flex-basis: 32%;
    margin: 0px;
    height: 300px;
    z-index: 1;
  }
  .urgent .anunt-pr{
    
    height: 300px;
    
  }
  .search-results h3{
    font-size: medium;
  }
  .search-results .anunt-pr li{
    margin: 0;
  }
}


@media screen and (max-width: 768px){
 .urgent .anunt-pr{
    width: 100%;
    max-width: none;
    flex-basis: 21%;
    margin: 0px;
    height: 300px;
    z-index: 1;
}

.search-results .anunt-pr{
  width: 100%;
  max-width: none;
  flex-basis: 32%;
  margin: 0px;
  height: 300px;
  z-index: 1;
}
.search-results h3, .urgent h3{
  font-size: medium;
}
.search-results .anunt-pr li, .urgent .anunt-pr li{
  margin: 0;
}
}

@media screen and (max-width: 520px) {
  .search-results ul {
    flex-direction: row;
    align-items: center; /* Center items in a column */
    z-index: 1;
  }

  .search-results .anunt-pr, .urgent .anunt-pr{
    width: 100%;
    max-width: none; /* Remove max-width */
    flex-basis: 47%; /* Explicitly set flex-basis to 100% for single column layout */
    margin:0px;
    height: 300px;
    z-index: 1;
    
  }
  .search-results .anunt-pr li {
    margin: 0;
  }
  .search-results h3 {
    font-size: medium;
  }

  #img-anunt {
    width: 90%;
    margin: 5%
  }

  .search-results li {
    margin-bottom: 10px;
  }
}


