.admin {
    
    
    
    height: 100vh;
    width: 100vw;
    padding: 5%;
    
    .users {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;
        width: 100%;
        

        button {
            
            height: 50px;
            align-self: center;
            
        }

        .listaadmin{
            margin-left: 1%;
            margin-right: 1%;
        }

    }

    .user-info{
        width: 20%;
        
    }

    .user {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-overflow: clip;
        width: 100%;
        background-color: #AAA439;
        border: 1px solid #000000;
        
        box-shadow: 4px 5px 0px 0 rgba(0, 0, 0, 1);

        p {
            line-height: 0;
        }
        
        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            align-self: center;
            left: 20px;
        }
        .pdescription {
            width: 30%;
        }
    }

    

}

@media screen and (max-width: 768px){
    .admin {
        .users {
            
            gap: 10px;
            width: 100%;
            button {
                height: 50px;
                align-self: center;
                
            }
        }
        .user-info{
            padding-left: 5%;
            width: 100%;
        }
        .user {
            
            justify-content: space-around;
            text-overflow: clip;
            width: 100%;
            background-color: #AAA439;
            border: 1px solid #000000;
            box-shadow: 4px 5px 0px 0 rgba(0, 0, 0, 1);
            p {
                line-height: 0%;
            }
            img {
                display: none;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                align-self: center;
                
            }
            .pdescription {
                display: none;
            }
            
        }
    }


    
}