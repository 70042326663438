/* Modal container */
.modal {
     /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal content */
  .modal-content {
    
    margin: 15% auto; /* 15% from the top and centered */
    padding: 0px;
    
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 500px; /* Maximum width */
    border-radius: 5px;
    
    position: relative;
    padding-bottom: 30px;
  }
  
  /* Close button */
  .close {
    color: #ffffff;
    float: right;
    font-size: 40px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }