.userProfile {
  
  display: grid; 
  grid-template-columns: 0.3fr 0.7fr 1.5fr 0.3fr; 
  grid-template-rows: 100px auto auto; 
  gap: 5px 5px; 
  grid-template-areas: 
    ". numeu numeu ."
    ". profileU profileUdata ."
    ". anuntUdata anuntUdata ."; 
    background-color: #AAA439;
    
}
.numeu { grid-area: numeu;
  background-color: #AAA439;
 }
.profileU { grid-area: profileU; 
  background-color: #AAA439;
  display: flex;
  flex-direction: column;
  align-items: center;

  
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    

    
  
  }
  h3{
    text-align: center;
  }
  
}
.profileUdata { grid-area: profileUdata; 
  background-color: #AAA439;
}
.anuntU { grid-area: anuntU; 
  background-color: #AAA439;
}
.anuntUdata { grid-area: anuntUdata; 
  background-color: #AAA439;
}

.profileUdata div {
  width: 100%;
  
  
  box-sizing: border-box;
  background-color: #AAA439;

}
.profileUdata form{
  background-color: #AAA439;
  color: #ffffff;
}



.prof-form input {
  
  color: #ffffff;
  
  border: 2px solid #000000;
  border-radius: 5px;
  box-shadow: 2px 2px 0px 0px #000000;
}

/* Existing styles */

/* Media query for mobile devices */
@media (max-width: 768px) {
  .userProfile {
    /* Adjust grid columns and rows for mobile */
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr auto;
    gap: 10px 10px;
    grid-template-areas:
      "numeu"
      "profileU" 
      "profileUdata"
      "anuntUdata";
  }



  /* Adjust the width of .profileUdata div */
  .profileUdata div {
    width: 100%;
  }
  .profileU{
    width: 100vw;
  }
  .numeu{
    width: 100vw;
  }
  .anuntUdata{
    width: 100vw;
  }
  

}



