@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Lexend+Mega:wght@700&family=Roboto:wght@500&display=swap');
body {
  margin: 0;
  
  font-family: 'Lexend Mega',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
