



.App {
  text-align: center;
  font-family: 'Lexend Mega',sans-serif;
}

nav {
  
  


  font-family: 'Lexend Mega';
  background-color: #ffffff;
  height: 50px;
  
  
  display: flex;
  flex-direction: row;
  color: rgb(0, 0, 0);
  
  z-index: 999;
  
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.55);
  justify-content: space-between;
  border-bottom: 2px solid #000000;
}

.rightc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5%;
}


#navig{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  


  
}

#navig button{

  margin-right: 20px;
  justify-content: flex-end;
  margin-left: 30px;
  background-color: #dccf0e;
  color: #000;
  border: 2px solid #000;
  box-shadow: 1px 1px 0px 0px #000;
  

}

#navig button:hover{

  margin-right: 20px;
  justify-content: flex-end;
  margin-left: 30px;
  background-color: #dccf0e;
  color: #000;
  border: 2px solid #000;
  box-shadow: 0px 0px 0px 0px #000;
  transform: translate(1px, 1px);
  

}

#navig button:active{

  margin-right: 20px;
  justify-content: flex-end;
  margin-left: 30px;
  background-color: #dccf0e;
  color: #000;
  border: 2px solid #000;
  box-shadow: 0px 0px 0px 0px #000;
  transform: scale(0.95);
  

}

#logo {
  display: flex;
  color: white; 
  align-items: center;
  
  height: 20px;
  width: auto;
  
  
  
  position: relative;
  padding: 20px;
  margin-left: 5%;

  
}





ul {
  list-style-type: none;
  margin: 50px;
  padding: 0;
  display: flex;
  flex: 1;
  align-items: top;
  justify-items: top;
  justify-content: space-between;
}

#navig li{
  display: inline;
  padding: 0 50px;
  justify-self: right;
  text-align: right;
  
}

li {
  display: inline;
  padding: 0 10px;
}

a {
  color: white;
  text-decoration: none;
}

.footer {
  
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #5c1414;
  color: rgb(255, 255, 255);
  text-align: center;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.55);
  position: relative;

  
  
}

.term {
  color: white;
  user-select: none;
}

.term:hover {
  color: #ffffff;
}

.termeni {
  z-index: 999;
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
}


#placuta {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  height: 50px;
  margin-right: 10%;
}

.contact {
  color: #ffffff;
  
}

#termeni {
  color: #ffffff;
  margin-left: 10%;
}

.table-lista td {
  padding: 10px;
  border: 1px solid #000000;
  text-align: left;
  font-size:  1rem;
  
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 3px solid #000000;


}

.table-lista tr {
  border-bottom: 3px solid #0a0707;
  background-color: #f2f2f2;
    
}
.table-lista tr:hover {
  background-color: #ece89c;
 
}

.table-lista th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(2, 2, 2);
  font-size:  1rem;
  border: 1px solid #000000;
}

.table-lista {
  
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  border-collapse: collapse;
  border: 3px solid #000000;
  font-size:  1rem;
  text-align: center;
  box-shadow: 4px 3px 0px 0px #000000;

}
::-webkit-scrollbar {
  display: none;
}


.adaugareAnunt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  
  border-collapse: collapse;
  border: 2px solid #ffffff;
  font-size:  1rem;
  text-align: center;
  padding: 5%;
}

select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #000000;
  background-color: #256F5C;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type=text], input[type=number], input[type=date], input[type=file] {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #000000;
  box-shadow: 2px 2px 0px 0px #000000;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #256F5C;
  color: #ffffff;
  

}

.nav-btn {
  display: inline-block;
  font-family: 'Chakra Petch', 'Roboto', sans-serif; 
  background-color: black;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size:  1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.55);
}

.signinup{
  display: flex;
}

.footer-links{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0%;
  margin-left: 5%;
  
}

#contact{
  color: white;
}

#carouseldiv{
  }

  #carousel {
    

  }

  .contact1{
    margin-top: 10%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0%;
    margin-left: 5%;
    text-align: center;
  }

  .contact1 input{
    border-bottom: 1px solid #000000;
    margin-bottom: 5%;
  }
  .contact1 h1{
    margin-bottom: 10%;
  }
  #rightbuttons {
    
    margin-left: 65%;
    color: #000;
    
    text-align: center;
  }

  #rightbuttons button{
    
    color: rgb(255, 255, 255);
    border: #ffffff;
    border-bottom: 1px solid #000000;
    
    
  }
  .adaugareAnunt {
    background-color: #AAA439;
    border-radius: 11px;
    border: 3px solid #000000;
    box-shadow: 4px 6px 0px 0px #000000;
    color: white;
    
  }

  .adaugareAnunt form {
    background-color: #AAA439;
  }
.urgente{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 5;
  text-align: center;
  padding-top: 5px;
  
  width: 100%;
  border-collapse: collapse;
  border: 0px 0px 0px 0px solid #000000;
  font-size:  1rem; 
  text-align: center;
  padding-left: 0%;
  padding-right: 0%;
  
  
  color: white;
  
  
  
}


  .anunt-u {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    
    
    height: 300px;
    border-collapse: collapse;
    border: 2px solid #000000;
    font-size:  1rem;
    text-align: center;
    box-sizing: border-box;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 20px;
    box-shadow: 3px 4px 0px 0px #5b6aae, 4px 6px 0px 0px #000000;
  }

  .anunt-u:hover{
  
  border: 3px solid black;
  box-shadow: 0px 0px 0px 0px #000000;
  cursor: pointer;
  transform: translate( 4px, 6px);
  }
  
  
  @media only screen and (max-width: 767px) {
    nav {
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .rightc {
      margin: 10px;
      justify-content: center;
    }
  
    #navig {
      padding: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    #navig button {
      margin: 5px;
      font-size: x-small;
    }
  
    #logo {
      top:0;
      padding: 10px;
      
    }
    .img-logo{
      top:0;
      padding-top: 15px;}
  
    ul {
      margin: 10px;
      justify-content: center;
    }
  
    #navig li {
      padding: 10px;
      text-align: center;
    }
  
    .footer {
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
    }
  
    .termeni {
      margin-left: 5%;
      margin-right: 5%;
    }
  
    #placuta {
      margin: 10px;
    }
  
    .contact {
      margin-right: 10px;
    }
  
    #termeni {
      margin-left: 10px;
    }
  
    .table-lista,
    .adaugareAnunt {
      margin: 5%;
      width: 90%;
      
    }

    .table-div {
      overflow-x: auto;
    }
  
    .nav-btn {
      margin: 10px;
    }
  }
  
  carousel-container {
    width: 100%;
  }

  .styled-alert {
    background-color: #000000;
    
  }