.searchBar {
    
    border: none;
    padding: 12px 0px;
    
    width: 100%;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    color: #ffffff;

    

    
    
    

}

.searchBar input{
    background-color: #256F5C;
    border: 2px solid black;
    
    
    padding: 11px 15px;
    margin: 8px 8px;
    flex: 3;
    border-radius: 5px;
    align-self: baseline;
    color: #ffffff;
}

.searchBar select {
    background-color: #256F5C;
    border: 2px solid black;
    box-shadow: 2px 2px 0px 0px #000000;
    margin: 8px 8px;
    flex: 1;
    color: white;
    border-radius: 5px;
}

.searchBar button{
    border-radius: 5px;
	border: 2px solid black;
    
   
	background-color: #256F5C;
	color: var(--alb);
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
    box-shadow: 2px 2px 0px 0px #000000;
}

.searchBar button:active {
	transform: scale(0.95);
}

.searchBar button:focus {
	outline: none;
}

/* .searchBar button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
} */

.searchBar option {
    background-color: var(--black);
    color: var(--alb);
    
    
    padding: 12px 15px;
    margin: 8px 8px;
    flex: 1;
    border-radius: 0px;
}

#searchCauta {
    color: #ffffff important!;
    background-color: #256F5C;
    
    

}
/* Existing styles */

@media (max-width: 768px) {
    /* Adjust the styles for the searchBar on mobile devices */
  
    .searchBar {
      flex-direction: column;
      align-items: stretch;
    }
  
    .searchBar input,
    .searchBar select,
    .searchBar button,
    .searchBar option {
      flex: 1;
      margin: 8px 0;
    }
  }
  
  /* Remaining styles remain unchanged */
  