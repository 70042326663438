
.carouseld .catbtn {
    

    width: 100%;
    background-color: var(--negru);
    color: var(--alb);
    border-radius: 0px;
    border-right: 2px solid #5c5f77;

    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    
    user-select: none;
    z-index: 10;
    text-align: center;
    font-size: 0.8rem;
    
}

.carouseld .catbtn:last-of-type {
    border-right: none;
}
.carouseld .catbtn:hover {
    

    width: 100%;
    background-color: #0c2b34;
    border: 1px solid black;
    
    
}

.carouseld {
    background-color: var(--s-color);
    margin-bottom: 10px;
}

.catbtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .category-icon {
    margin-bottom: 8px; /* Adjust as needed to control the space between icon and text */
    font-size: 2.5rem;
  }

  
  /* Adjust the position to maintain the centering */
.react-multiple-carousel__arrow--left {
    left: calc(4% + 1px - 4%);
  }
  
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px - 4%);
  }

  /* Remove the background of the arrows */
.react-multiple-carousel__arrow {
    background: transparent; /* or background: none; */
    color: #3498db; /* Change the text color if needed */
    font-size: 24px; /* Adjust the font size if needed */
    border: none; /* Remove border if there is any */
    box-shadow: none; /* Remove box-shadow if there is any */
  }
  
  .react-multiple-carousel__arrow:hover {
    background: transparent; /* or background: none; */
  }
  
  /* If you want to change the arrow icons, you can use the ::before pseudo-element */
  /* .react-multiple-carousel__arrow::before {
    content: "\2190";
  }
  
  .react-multiple-carousel__arrow--right::before {
    content: "\2192";
  } */

  @media (max-width: 768px) {
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #802422;
      width: 100%; /* Make the dropdown content match the button's width */
      border: 2px solid #000000;
      box-sizing: border-box; /* Include padding in width calculation */
      margin-top: 0px; /* Add space between the dropdown and the button */
      border-radius: 0px;
      
      z-index: 99;
      padding: 0px;
    }
    
    
    
    
    .categories-drp{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 0%;
      margin-bottom: 0%;
      margin-left: 0%;
      margin-right: 0%;
      width: 80%;
      border-collapse: collapse;
      padding: 0%;
      
    
      
    }
    .carouseld .catbtn {
      width: 100%;
      height: 4rem;
      border-radius: 0px;
      border-right: 2px solid #5c5f77;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .carouseld .catbtn:last-of-type {
      border-right: none;
    }
    .carouseld .catbtn:hover {
      width: 100%;
      background-color: #0c2b34;
      border: 1px solid var(--f-color);
    }
    .carouseld {
      background-color: var(--s-color);
      margin-bottom: 10px;
    }
    .catbtn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .category-icon {
      margin-bottom: 8px; /* Adjust as needed to control the space between icon and text */
      font-size: 1.5rem;
    }
    .react-multiple-carousel__arrow--left {
      left: calc(8% + 1px - 8%);
    }
    .react-multiple-carousel__arrow--right {
      right: calc(8% + 1px - 8%);
    }
    .react-multiple-carousel__arrow {
      background: transparent; /* or background: none; */
      color: #3498db; /* Change the text color if needed */
      font-size: 24px; /* Adjust the font size if needed */
      border: none; /* Remove border if there is any */
      box-shadow: none; /* Remove box-shadow if there is any */
    }
    .react-multiple-carousel__arrow:hover {
      background: transparent; /* or background: none; */
    }
  }

  /* @media only screen and (max-width: 768px) {
    .carouseld .catbtn {
        height: auto;
        padding: 15px; 
        font-size: 1rem; 

    .catbtn {
        margin-bottom: 10px; 
    }

    .category-icon {
        margin-bottom: 5px; 
        font-size: 2rem; 
} */

.categories-drp{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  border-collapse: collapse;
  

  font-size: 15px;
  text-align: center;
  padding: 5%;
  z-index: 99;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.category-item {
  flex: 1;
  margin: 0;
  padding: 0;
  text-align: center;
}

.category-image {
  width: 50px;
  height: 50px;
}

label {
  display: block;
}





.dropdown-container {
  display: flex;
  z-index: 99;
  
}

.dropdown {
  position: relative;
  z-index: 99;
  flex: 1; /* Let the dropdown take up all available space */
}

.dropbtn {
  background-color: #37256f;
  color: white;
  width: 100%; /* Make the button take up the entire width of the dropdown */
  padding: 10px ; /* Adjust padding */
  font-size: 16px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center; /* Align text to the left */
  height: 125px;
  justify-content: center;
  border-radius: 0%;
  
  
}

  .dropbtn:hover {
      background-color: #6f2538;
  }
  .material-symbols-outlined{
      font-size: 50px;
  }
  .button-text {
      font-size: 20px;
      font-weight: bold;
  }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #6f2538;
  width: 100%; /* Make the dropdown content match the button's width */
  border: 2px solid #000000;
  box-sizing: border-box; /* Include padding in width calculation */
  margin-top: 0px; /* Add space between the dropdown and the button */
  border-radius: 0px;
  
  z-index: 99;
  padding: 15px;
}

.dropdown-content a {
  color: rgb(255, 255, 255);
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  margin:0;
  
  
}

.dropdown-content a:hover {
  background-color: #5c1414;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}
.cont {
  position: relative;
  z-index: 1;
}


.material-symbols-outlined {
font-variation-settings:
'FILL' 0,
'wght' 400,
'GRAD' 0,
'opsz' 24
}

@media (max-width: 768px) {
  .dropdown {
    position: static;
    z-index: 99;
    flex: 1;
  }
  
  .dropdown-content {
    left: 0px;
    display: none;
    position: absolute;
    background-color: #6e2a29;
    width: 100vw; /* Make the dropdown content match the button's width */
    border: 2px solid #000000;
    box-sizing: border-box; /* Include padding in width calculation */
    margin-top: 0px; /* Add space between the dropdown and the button */
    border-radius: 0px;
    
    z-index: 99;
    padding: 0px;
  }
  
  .dropdown-content a {
    color: rgb(255, 255, 255);
    padding: 5px 5px;
    overflow: hidden;
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin:0;
    
    
  }
  
  
  .categories-drp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0%;
    margin-bottom: 0%;
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    border-collapse: collapse;
    padding: 0%;
    
  
    
  }}