.userPage {
    display: grid;
    grid-template-columns: 0.1fr 0.8fr 0.1fr;
    grid-template-areas:
        ". userPageProfile ."
        ". userPageAnunturi .";
    flex-direction: column;
    

}

.userPageProfile {
    
    grid-area: userPageProfile;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    border: 3px solid #000;
    padding: 10px;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 3px 4px 0px 0px #000000;
    
    

    img{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 1%;
        margin-right: 5%
    }
    


}

.userPageAnunturi {
    
    grid-area: userPageAnunturi;
    
    background-color: #AAA439;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
    padding-top: 0%;
    border: 3px solid #000;
    margin: 2%;
    border-radius: 10px;
    box-shadow: 3px 4px 0px 0px #000000;

    img {
        width: 100%;
    }

    .userPageAnunt {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-overflow: clip;
        width: 100%;
        

        p {
            line-height: 0;
        }

        img {
            width: 150px;
            height: 100px;
            object-fit: cover;
            align-self: center;
            left: 20px;
        }
        .pdescription {
            width: 30%;
        }
        .upanunt {

            img{
                margin-right: 5%;
                border: 1px solid #000;
            }
            
            display: flex;
            flex-direction: row;
            
            align-items: center;
            margin: 1%;
            background-color: white;
            border: 2px solid #000000;
            border-radius: 10px;
            box-shadow: 3px 4px 0px 0px #000000;
        }
        .upanunt:hover {
            transform: translate( 3px, 4px);
            box-shadow: none;
            cursor: pointer;
        }
    }
    


}

@media screen and (max-width: 768px) {
    .userPage {
        display: flex;
        flex-direction: column;
    }

    .userPageProfile {
        img {
            width: 100px;
            height: 100px;
        }
    }

    .userPageAnunturi {
        .userPageAnunt {
            img {
                width: 100px;
                height: 100px;
            }
        }
    }
    
}