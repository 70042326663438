.container-home {
  display: border-box;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes at least the viewport height */
}




.home {
    font-family: Roboto;
    background-color: #dccf0e4f;
    display: grid;
    grid-row: auto;
    grid-template-columns: 0.3fr 1.7fr 1.7fr 0.3fr; 
    grid-template-rows: auto auto auto auto 50px; 
    
    column-gap: 5px;
    grid-template-areas: 
      "search-bar search-bar search-bar search-bar"
      "categories categories categories categories"
      "urgent urgent urgent urgent"
      ". search search ."
      "footer footer footer footer"; 
    
    
    
  }

  .bl{
    grid-area: bl;
    background-color: var(--negru);
  }

  .br{
    grid-area: br;
    background-color: var(--negru);
  }
  .r {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color:white;
    height: 90%;
    
  }
  .search-bar { grid-area: search-bar;
    
  padding: 2% 5% 2% 5%;
  background-color: #AA6339;
  border-bottom: 3px solid #000000;
  
  }
  .categories { grid-area: categories;
    z-index: 99;
    
    background-color:var(--negru);
    border-bottom: 5px solid #000000;
    
    
    
   }
   .urgent { grid-area: urgent; 
    border: #dccf0e4f;
    z-index: 1;
    padding-top: 20px;
    height: 400px;
    background: rgba(255, 255, 255, 0);
    
    
    
    
    
   
   }

   .slick-slide {
   /* Adjust the margin value as needed */
  padding: 0 5px;
  

}


/* Optional: If you want to set a fixed width for the slides */
.slick-slide img {
  width: 100%; /* or any other fixed width you want */
  
  
}

.slick-prev{
  left:10px;
  height: 50px !important;
  
  box-shadow: none;
  color: #000000  !important;
  z-index: 100;



}

.slick-next{
  right:40px;
  height: 50px !important;
  box-shadow: none;
  
  
  color: #000000 !important;
  z-index: 100;
}

.slick-prev:before, .slick-next:before {
  color: #000000 !important;
  font-size: 50px !important;
  opacity: 1 !important;
}


  .search { grid-area: search; 
    /* background-color: #dccf0e4f; */
    z-index: 5;
    min-height: 500px;

  }
  
  .footer { grid-area: footer; 
    background-color: #6f2538;
    z-index: 100;
    
    bottom: 0;
  }
.footer-links {
  height: 100%;
  
}
@media screen and (max-width:1200px) {
  
    .urgent {
      height: 370px;
    
    }
  
}

  @media screen and (max-width: 768px) {
    .home {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto 50px;
      grid-template-areas: search-bar
        categories
        search
        footer;
      display: flex;
      flex-direction: column;
    }
  
    .bl,
    .br {
      grid-area: bl;
      grid-area: br;
      background-color: var(--negru);
    }
  
    .r {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
      background-color: rgba(255, 255, 255, 0.41);
      height: 90%;
      display: flex;
    }
  
    .search-bar {
      grid-area: search-bar;
      padding: 0% 5% 2% 5%;
      background-color:#283d8a#AA6339;;
      display: flex;
      flex-direction: column;
    }
  
    .categories {
      grid-area: categories;
      z-index: 5;
      background-color:black;
      border-bottom: 3px solid black;
      padding-bottom: 1%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
  
    .search {
      grid-area: search;
      background-color: var(--s-color);
      z-index: 1;
      min-height: 500px;
      display: flex;
      flex-direction: column;
    }
  
    .footer {
      grid-area: footer;
      margin-top: 10px;
      background-color: #5c1414;
      z-index: 100;
      bottom: 0;
      p{
        margin: 0;
      }
      .footer-links {
        margin: 0;
      }
      
    }
  }

  input::placeholder{
    color: white;
    
  }

